<!--
 * @Author: frank
 * @Description: 
 * @Date: 2021-01-15 14:56:51
 * @LastEditTime: 2021-04-12 11:20:46
 * @FilePath: /shop_frontend/src/views/freightTemplate/AddWeightTem.vue
-->
<template>
  <div class="tem">
    <div class="tem__content" :style="{ height: height + 'px' }">
      <a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol" ref="ruleForm" :rules="rules">
        <a-form-model-item label="模板名称" prop="name">
          <a-input v-model="form.name" class="w220" />
        </a-form-model-item>
        <!-- <a-form-model-item label=" " :colon="false">
          <div class="tem__checkbox">
            <a-checkbox> 除可配送区域外，其他不可配送 </a-checkbox>
          </div>
        </a-form-model-item> -->
        <a-form-model-item label=" " :colon="false" :wrapperCol="{ span: 20 }">
          <div>
            <span>其他区域运费：</span
            ><span>
              <a-input style="width: 100px; margin: 0 4px" v-model="form.initial_weight"></a-input>
            </span>
            <span>公斤内</span>
            <span>
              <a-input style="width: 100px; margin: 0 4px" v-model="form.initial_amount"></a-input>
            </span>
            <span>元，每增加</span>
            <span>
              <a-input style="width: 100px; margin: 0 4px" v-model="form.subsequent_weight"></a-input>
            </span>
            <span>公斤，增加运费</span>
            <span>
              <a-input style="width: 100px; margin: 0 4px" v-model="form.subsequent_amount"></a-input>
            </span>
            <span>元</span>
          </div>
        </a-form-model-item>
        <a-form-model-item label="配送区域" :wrapperCol="{ span: 20 }">
          <div>
            <a-table :columns="columns" :data-source="freight_rule_items" :pagination="false">
              <div slot="city" slot-scope="text, record, index">
                <div style="text-align: center">{{ record.name || "无" }}</div>
                <div style="text-align: center">
                  <!-- <a-button type="link" @click="editCity(record)"
                    >编辑</a-button
                  > -->
                  <a-button type="link" style="color: red" @click="delCity(record, freight_rule_items, index)"
                    >删除</a-button
                  >
                </div>
              </div>
              <template slot="initial_weight" slot-scope="text, record">
                <a-input class="w120" v-model="record.initial_weight"></a-input>
              </template>
              <template slot="initial_amount" slot-scope="text, record">
                <a-input class="w120" v-model="record.initial_amount"></a-input>
              </template>
              <template slot="subsequent_weight" slot-scope="text, record">
                <a-input class="w120" v-model="record.subsequent_weight"></a-input>
              </template>
              <template slot="subsequent_amount" slot-scope="text, record">
                <a-input class="w120" v-model="record.subsequent_amount"></a-input>
              </template>
            </a-table>
          </div>
        </a-form-model-item>
        <a-form-model-item label=" " :colon="false">
          <a-button type="link" @click="openDialog">指定配送单位和运费</a-button>
        </a-form-model-item>
        <a-form-model-item label=" " :colon="false">
          <div class="">
            <a-checkbox v-model="free"> 指定条件包邮(可选) </a-checkbox>
          </div>
        </a-form-model-item>
        <a-form-model-item label=" " :wrapperCol="{ span: 20 }" :colon="false">
          <div>
            <a-table :columns="columns2" :data-source="freight_free_rule_items" :pagination="false">
              <div slot="city" slot-scope="text, record, index">
                <div style="text-align: center">{{ record.name || "无" }}</div>
                <div style="text-align: center">
                  <!-- <a-button type="link" @click="editCity(record)"
                    >编辑</a-button
                  > -->
                  <a-button type="link" style="color: red" @click="delCity(record, freight_free_rule_items, index)"
                    >删除</a-button
                  >
                </div>
              </div>
              <template slot="freeRule" slot-scope="text, record">
                <a-select style="width: 120px" v-model="record.condition">
                  <a-select-option :value="0"> 公斤数 </a-select-option>
                  <a-select-option :value="1"> 金额 </a-select-option>
                  <a-select-option :value="2"> 公斤数+金额 </a-select-option>
                </a-select>
                <span style="margin-left: 10px; margin-right: 10px">在</span>
                <span v-if="record.condition == 0">
                  <a-input
                    class="w120"
                    v-model.number="record.within_weight"
                    type="number"
                    style="margin-right: 10px"
                  ></a-input>
                  <span style="margin-left: 10px">公斤内包邮</span>
                </span>
                <span v-if="record.condition == 1">
                  <a-input class="w120" v-model="record.within_amount"></a-input>
                  <span style="margin-left: 10px">元包邮</span>
                </span>
                <span v-if="record.condition == 2">
                  <a-input class="w120" v-model="record.within_weight"></a-input>
                  <span style="margin-left: 10px; margin-right: 10px">公斤内，满</span>
                  <a-input class="w120" v-model="record.within_amount"></a-input>
                  <span>元包邮</span>
                </span>
              </template>
            </a-table>
          </div>
        </a-form-model-item>
        <a-form-model-item label=" " :colon="false">
          <a-button type="link" @click="openFree">指定可包邮配送区域和条件</a-button>
        </a-form-model-item>
        <a-form-model-item label=" " :colon="false">
          <div style="text-align: center">
            <a-button type="primary" @click="commitTem">{{ isEdit ? "修改" : "添加" }}模板</a-button>
          </div>
        </a-form-model-item>
      </a-form-model>
    </div>
    <!-- <div class="tem__btn">
      <a-button type="primary" @click="commitTem">添加模板</a-button>
    </div> -->

    <a-modal title="区域" :visible="visible" @ok="handleOk" @cancel="visible = false" :width="800" v-if="visible">
      <div class="city__content">
        <a-row style="border-bottom: 1px solid #ddd; padding: 10px">
          <a-col span="6">
            <a-checkbox :indeterminate="indeterminate" :checked="checkAll" @change="onCheckAllChange">全 选</a-checkbox>
          </a-col>
        </a-row>
        <a-row v-for="el in provinces" :key="el.code" style="border-bottom: 1px solid #ddd; padding: 10px">
          <a-col span="6">
            <a-checkbox :checked="el.checked" :indeterminate="el.indeterminate" @change="changeAline(el, $event)">{{
              el.name
            }}</a-checkbox>
          </a-col>
          <a-col span="16">
            <a-row>
              <a-checkbox-group style="width: 100%" v-model="el.checkedList" @change="onChange(el)">
                <a-col :span="8" v-for="item in el.cities" :key="item.id">
                  <a-checkbox :value="item.id"> {{ item.name }} </a-checkbox>
                </a-col>
              </a-checkbox-group>
            </a-row>
          </a-col>
        </a-row>
      </div>
    </a-modal>
  </div>
</template>

<script>
const columns = [
  {
    title: "可配送区域",
    dataIndex: "cities",
    key: "cities",
    scopedSlots: { customRender: "city" },
    align: "center",
  },
  {
    title: "首件(公斤)",
    dataIndex: "initial_weight",
    key: "initial_weight",
    scopedSlots: { customRender: "initial_weight" },
  },
  {
    title: "运费(元)",
    dataIndex: "initial_amount",
    key: "initial_amount",
    scopedSlots: { customRender: "initial_amount" },
  },
  {
    title: "续公斤(公斤)",
    dataIndex: "subsequent_weight",
    key: "subsequent_weight",
    scopedSlots: { customRender: "subsequent_weight" },
  },
  {
    title: "续费(元)",
    dataIndex: "subsequent_amount",
    key: "subsequent_amount",
    scopedSlots: { customRender: "subsequent_amount" },
  },
];
const columns2 = [
  {
    title: "可配送区域",
    dataIndex: "cities",
    key: "cities",
    scopedSlots: { customRender: "city" },
    align: "center",
  },
  {
    title: "设置包邮条件",
    dataIndex: "freeRule",
    key: "freeRule",
    scopedSlots: { customRender: "freeRule" },
  },
];
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      indeterminate: false,
      checkAll: false,
      visible: false,
      free: false,
      freight_rule_items: [],
      freight_free_rule_items: [],
      rules: {
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
      },
      columns2,
      columns,
      height: 0,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      form: {
        name: "",
        initial_weight: 0,
        initial_amount: 0,
        subsequent_weight: 0,
        subsequent_amount: 0,
      },
      provinces: [],
      currentFree: false,
    };
  },
  computed: {
    isEdit() {
      return this.$route.query.isEdit;
    },
  },
  watch: {
    $route: {
      handler: function() {
        if (this.$route.query.isEdit) {
          console.log(this.$parent, "parent");

          let json = JSON.parse(this.$route.query.form);

          this.form = {
            name: json.name,
            initial_weight: json.initial_weight,
            initial_amount: json.initial_amount,
            subsequent_weight: json.subsequent_weight,
            subsequent_amount: json.subsequent_amount,
            id: json.id,
          };
          this.freight_rule_items = json.freight_rule_items || [];
          this.freight_free_rule_items = json.freight_free_rule_items || [];
          this.free = this.freight_free_rule_items.length > 0 ? true : false;

          let list = [];
          let cities = [];
          this.provinces.forEach((el) => {
            console.log(el);
            list = [...list, ...el.checkedList];
            cities = [...cities, ...el.cities];
          });
          let checkedCities = [];
          list.forEach((el) => {
            cities.forEach((m) => {
              if (el == m.id) {
                checkedCities.push(m);
              }
            });
          });
          let str = checkedCities.map((el) => el.name).join(",");
          let arr = checkedCities.map((el) => el.id);

          this.freight_free_rule_items.push({
            name: str,
            cities: arr,
            within_weight: 0,
            within_amount: 0,
            condition: 0,
            within_weight: 0,
            within_amount: 0,
          });
          this.freight_free_rule_items.splice(-1, 1);

          this.freight_rule_items.push({
            name: str,
            cities: arr,
            initial_weight: 0,
            initial_amount: 0,
            subsequent_weight: 0,
            subsequent_amount: 0,
          });
          this.freight_rule_items.splice(-1, 1);

          if (this.$route.query.isEdit) {
            for (let item of this.freight_rule_items) {
              let nameList = [];
              for (let checkedCity of item.cities) {
                for (let cityItem of this.provinces) {
                  if (checkedCity == cityItem.id) {
                    nameList.push(cityItem.name);
                  }
                }
              }

              item.name = nameList.join(",");
            }

            for (let item of this.freight_free_rule_items) {
              let nameList = [];
              for (let checkedCity of item.cities) {
                for (let cityItem of this.provinces) {
                  if (checkedCity == cityItem.id) {
                    nameList.push(cityItem.name);
                  }
                }
              }

              item.name = nameList.join(",");
            }
          }
        }
      },
      immediate: true,
    },
  },
  created() {
    this.height = this.$parent.$parent.$parent.$el.clientHeight - 60 - 32;
    this.getProvinces();
  },
  methods: {
    ...mapMutations(["setReload"]),
    openDialog() {
      this.visible = true;
      this.currentFree = false;
    },
    openFree() {
      if (!this.free) {
        this.$message.warning("请勾选指定条件包邮");
        return;
      }
      this.visible = true;
      this.currentFree = true;
    },
    onChange(obj) {
      obj.indeterminate = !!obj.checkedList.length && obj.checkedList.length < obj.cities.length;
      obj.checked = obj.checkedList.length === obj.cities.length;
      let t1 = this.provinces.reduce((total, el) => {
        return (total += el.cities.length);
      }, 0);
      let t2 = this.provinces.reduce((total, el) => {
        return (total += el.checkedList.length);
      }, 0);
      this.checkAll = t1 === t2;
      this.indeterminate = !!t2 && t2 < t1;
    },
    changeAline(obj, e) {
      this.$set(obj, "checked", e.target.checked);
      this.$set(obj, "indeterminate", false);
      this.$set(obj, "checkedList", e.target.checked ? obj.cities.map((el) => el.id) : []);
      let t1 = this.provinces.reduce((total, el) => {
        return (total += el.cities.length);
      }, 0);
      let t2 = this.provinces.reduce((total, el) => {
        return (total += el.checkedList.length);
      }, 0);
      this.checkAll = t1 === t2;
      this.indeterminate = !!t2 && t2 < t1;
    },
    onCheckAllChange(e) {
      this.checkAll = e.target.checked;
      let _this = this;
      this.provinces.forEach((el) => {
        _this.$set(el, "checked", e.target.checked);
        let list = e.target.checked ? el.cities.map((m) => m.id) : [];
        _this.$set(el, "checkedList", list);
      });
      this.indeterminate = false;
    },
    getProvinces() {
      this.$axios.get("/provinces/").then((res) => {
        console.log("/provinces/", res);
        let _this = this;
        this.provinces = res || [];
        this.provinces.forEach((el) => {
          _this.$set(el, "checkedList", []);
        });

        let list = [];
        let cities = [];
        this.provinces.forEach((el) => {
          console.log(el);
          list = [...list, ...el.checkedList];
          cities = [...cities, ...el.cities];
        });
        let checkedCities = [];
        list.forEach((el) => {
          cities.forEach((m) => {
            if (el == m.id) {
              checkedCities.push(m);
            }
          });
        });
        let str = checkedCities.map((el) => el.name).join(",");
        let arr = checkedCities.map((el) => el.id);

        this.freight_free_rule_items.push({
          name: str,
          cities: arr,
          within_weight: 0,
          within_amount: 0,
          condition: 0,
          within_weight: 0,
          within_amount: 0,
        });
        this.freight_free_rule_items.splice(-1, 1);

        this.freight_rule_items.push({
          name: str,
          cities: arr,
          initial_weight: 0,
          initial_amount: 0,
          subsequent_weight: 0,
          subsequent_amount: 0,
        });
        this.freight_rule_items.splice(-1, 1);

        if (this.$route.query.isEdit) {
          for (let item of this.freight_rule_items) {
            let nameList = [];
            for (let checkedCity of item.cities) {
              for (let cityItem of this.provinces) {
                if (checkedCity == cityItem.id) {
                  nameList.push(cityItem.name);
                }
              }
            }

            item.name = nameList.join(",");
          }

          for (let item of this.freight_free_rule_items) {
            let nameList = [];
            for (let checkedCity of item.cities) {
              for (let cityItem of this.provinces) {
                if (checkedCity == cityItem.id) {
                  nameList.push(cityItem.name);
                }
              }
            }

            item.name = nameList.join(",");
          }
        }
      });
    },
    editCity(obj) {
      this.visible = true;
    },
    delCity(obj, list, index) {
      console.log(obj, list, index);
      list.splice(index, 1);
    },
    handleOk() {
      this.visible = false;
      let list = [];
      let cities = [];
      this.provinces.forEach((el) => {
        console.log(el);
        list = [...list, ...el.checkedList];
        cities = [...cities, ...el.cities];
      });
      let checkedCities = [];
      list.forEach((el) => {
        cities.forEach((m) => {
          if (el == m.id) {
            checkedCities.push(m);
          }
        });
      });
      let str = checkedCities.map((el) => el.name).join(",");
      let arr = checkedCities.map((el) => el.id);
      if (this.currentFree) {
        this.freight_free_rule_items.push({
          name: str,
          cities: arr,
          within_weight: 0,
          within_amount: 0,
          condition: 0,
          within_weight: 0,
          within_amount: 0,
        });
      } else {
        this.freight_rule_items.push({
          name: str,
          cities: arr,
          initial_weight: 0,
          initial_amount: 0,
          subsequent_weight: 0,
          subsequent_amount: 0,
        });
      }
    },
    clearForm() {
      this.form = {
        name: "",
        initial_weight: 0,
        initial_amount: 0,
        subsequent_weight: 0,
        subsequent_amount: 0,
      };
      this.freight_free_rule_items = [];
      this.freight_rule_items = [];
      this.free = false;
    },
    commitTem() {
      console.log(this.free, "free");
      console.log(this.$parent.$children);
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          // alert('submit!');
          console.log(this.form, "form");
          let data = {
            ...this.form,
            freight_rule_items: this.freight_rule_items,
            // freight_free_rule_items: this.freight_free_rule_items
          };
          if (this.free) {
            data.freight_free_rule_items = this.freight_free_rule_items;
          }

          let request = null;
          if (this.isEdit) {
            request = this.$axios.put("/freight_templates/" + this.form.id + "/", data);
          } else {
            request = this.$axios.post("/freight_templates/", data);
          }
          request.then((res) => {
            console.log(res);
            this.$message.success("操作成功");
            this.clearForm();
            this.setReload(true);
            if (this.isEdit) {
              this.$router.replace({ name: "freightTemplate" });
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.tem {
  &__content {
    overflow-y: auto;
  }
  &__btn {
    text-align: center;
  }
  &__checkbox {
    background: #fdf6ec;
    padding: 30px 10px;
  }
}
.city__content {
  height: 700px;
  overflow: hidden;
  overflow-y: auto;
}
</style>
